<template>
  <div>
    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error"></ErrorMessage>

    <div v-if="!isLoading && !error && nutrition" class="card">
      <form
        @submit.prevent="save"
        :class="{ 'is-busy': isSaving, 'is-editing': isEditing }"
      >
        <div class="d-flex align-items-center justify-content-end border-bottom p-3">
          <button
            @click.prevent="isEditing = !isEditing"
            :class="{ 'is-invisible': isEditing }"
            class="btn btn-sm btn-primary ms-2"
          >Update</button>
          <IconButton
            @click="download"
            v-if="!isEditing"
            :icon="'download'"
            :text="'Download'"
            class="btn btn-sm btn-primary ms-2"
          ></IconButton>
          <button
            @click="cancelEditing"
            v-if="isEditing"
            class="btn btn-sm btn-danger ms-2"
          >Cancel</button>
          <SubmitButton
            v-if="isEditing"
            :text="'Save'"
            :textBusy="'Saving...'"
            :isLoading="isSaving"
            :disabled="isSaving || isSavingDisabled"
            class="btn btn-sm btn-primary ms-2"
          ></SubmitButton>
        </div>

        <div ref="downloadable">
          <div class="m-3">
            <div class="d-flex align-items-center mb-3">
              <h5 class="flex-grow-1 m-0 me-3">Macro Distribution</h5>
              <div v-if="isEditing" class="btn-group btn-group-sm" role="group">
                <button
                  @click="usePercentInEditing = true"
                  :class="{ active: usePercentInEditing }"
                  type="button"
                  class="btn btn-outline-primary"
                >Percentage (%)</button>
                <button
                  @click="usePercentInEditing = false"
                  :class="{ active: !usePercentInEditing }"
                  type="button"
                  class="btn btn-outline-primary"
                >Gram (g)</button>
              </div>
            </div>
            <div class="d-flex macro-card-container">
              <div
                v-for="(macro, macroIndex) in macros"
                :key="`key-${macroIndex}`"
                class="macro-card"
              >
                <div class="card h-100">
                  <div class="card-body">
                    <h6 class="card-subtitle">{{ macro.name }}</h6>
                    <div v-if="isEditing">
                      <div class="mt-3 form-floating">
                        <input
                          v-model.number="nutrition[macro.slug].total.value"
                          type="number"
                          step=".01"
                          inputmode="decimal"
                          class="form-control"
                          id="mt"
                          placeholder="Daily calories"
                        >
                        <label for="mt">Daily calories</label>
                      </div>

                      <NutritionMacronutrientInputGroup
                        @macroUpdate="updateMacro(macro.slug, $event)"
                        v-model="nutrition[macro.slug]"
                        v-show="!usePercentInEditing"
                        class="mt-3"
                      ></NutritionMacronutrientInputGroup>

                      <NutritionMacronutrientSliderGroup
                        @macroUpdate="updateMacro(macro.slug, $event)"
                        @percentageUpdate="updateMacroPercentage(macro.slug, $event)"
                        v-model="nutrition[macro.slug]"
                        v-show="usePercentInEditing"
                        class="mt-3"
                      ></NutritionMacronutrientSliderGroup>
                    </div>
                    <div v-else class="list-group list-group-flush mt-3">
                      <div class="list-group-item">
                        <div class="small text-muted">Total calories (cals)</div>
                        <div>{{ nutrition[macro.slug].total.value }}</div>
                      </div>
                      <div class="list-group-item">
                        <div class="small text-muted">Carbohydrate (g)</div>
                        <div>{{ nutrition[macro.slug].c.value }}</div>
                      </div>
                      <div class="list-group-item">
                        <div class="small text-muted">Protein (g)</div>
                        <div>{{ nutrition[macro.slug].p.value }}</div>
                      </div>
                      <div class="list-group-item">
                        <div class="small text-muted">Fat (g)</div>
                        <div>{{ nutrition[macro.slug].f.value }}</div>
                      </div>
                      <div
                        v-if="hasData(nutrition[macro.slug])"
                        data-html2canvas-ignore
                        class="list-group-item"
                      >
                        <NutritionDonut
                          :macronutrients="nutrition[macro.slug]"
                          class="mt-3 donut-container"
                        ></NutritionDonut>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="m-3">
            <h5 class="m-0 mb-3">Weekly Planner</h5>
            <div class="d-flex text-center weekplan border rounded bg-white">
              <div
                v-for="(plan, planIndex) in nutrition.dailyPlan"
                :key="`key-x-${planIndex}`"
                :class="planIndex > 4 ? 'weekend' : 'weekday'"
              >
                <h6 class="m-0 p-2">{{ day(planIndex) }}</h6>

                <!-- SURPLUS -->
                <div
                  @click="setPlan(planIndex, 1)"
                  :class="{ active: plan === 1 }"
                  :role="isEditing ? 'button' : ''"
                  class="p-2 border-top macro macro-surplus"
                >
                  <div
                    :class="`align-items-center justify-content-center`"
                    class="d-flex flex-wrap flex-column flex-md-row h-100"
                  >
                    <fa-icon :icon="['fas', 'caret-up']" class="icon"></fa-icon>
                  </div>
                </div>

                <!-- MAINTENANCE -->
                <div
                  @click="setPlan(planIndex, 0)"
                  :class="{ active: plan === 0 }"
                  :role="isEditing ? 'button' : ''"
                  class="p-2 border-top macro macro-maintenance"
                >
                  <div
                    :class="`align-items-center justify-content-center`"
                    class="d-flex flex-wrap flex-column flex-md-row h-100"
                  >
                    <fa-icon :icon="['fas', 'minus']" class="icon"></fa-icon>
                  </div>
                </div>

                <!-- DEFICIT -->
                <div
                  @click="setPlan(planIndex, -1)"
                  :class="{ active: plan === -1 }"
                  :role="isEditing ? 'button' : ''"
                  class="p-2 border-top macro macro-deficit"
                >
                  <div
                    :class="`align-items-center justify-content-center`"
                    class="d-flex flex-wrap flex-column flex-md-row h-100"
                  >
                    <fa-icon :icon="['fas', 'caret-down']" class="icon"></fa-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { httpGet, httpPost } from '@/core/http';
import Factory from '@/core/factory';
import { deepCopy } from '@/core/util';

export default {
  name: 'NutritionForm',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    IconButton: () => import('@/components/button/IconButton'),
    NutritionMacronutrientSliderGroup: () => import('@/components/nutrition/NutritionMacronutrientSliderGroup'),
    NutritionMacronutrientInputGroup: () => import('@/components/nutrition/NutritionMacronutrientInputGroup'),
    NutritionDonut: () => import('@/components/nutrition/NutritionDonut'),
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isSavingDisabled() {
      const { maintenance, surplus, deficit } = this.isPercentageValid;
      return !maintenance || !surplus || !deficit;
    },
  },
  watch: {
    nutrition(newValue) {
      this.nutritionBackup = deepCopy(newValue);
    },
  },
  methods: {
    async fetchNutrition() {
      this.isLoading = true;
      this.error = null;
      try {
        const res = await httpGet(`/nutrition/${this.userId}`);
        if (res.data) {
          const { userId, data } = res.data;
          if (userId === this.userId) {
            this.nutrition = JSON.parse(data);
          } else {
            this.nutrition = Factory.createNutrition();
          }
        } else {
          this.nutrition = Factory.createNutrition();
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async save() {
      if (!this.isEditing) return;
      this.isSaving = true;
      try {
        const data = JSON.stringify(this.nutrition);
        await httpPost(`/nutrition/${this.userId}`, { data });
        await this.fetchNutrition();
        this.isEditing = false;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isSaving = false;
      }
    },
    async download() {
      const element = this.$refs.downloadable;

      // HTML2CANVAS note:
      // Add width and height to SVG elements, otherwise they won't be rendered.
      const svgElements = element.querySelectorAll('svg');
      for (let i = 0; i < svgElements.length; i += 1) {
        const item = svgElements[i];
        item.setAttribute('width', item.getBoundingClientRect().width);
        item.setAttribute('height', item.getBoundingClientRect().height);
        item.style.width = null;
        item.style.height = null;
      }

      try {
        const width = element.clientWidth;
        const height = element.clientHeight;
        const ratio = height / width;
        const canvas = await html2canvas(element, { width, height });
        const image = canvas.toDataURL('image/jpeg');
        const doc = new jsPDF({
          orientation: 'landscape',
          unit: 'mm',
          pageSize: 'a4',
        });
        const docWidth = doc.internal.pageSize.getWidth();
        const docHeight = ratio * docWidth;
        doc.addImage(image, 'JPEG', 20, 20, docWidth - 40, docHeight - 40);
        doc.save(`nutrition-${+new Date()}.pdf`);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
    day(index) {
      const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      return days[index];
    },
    setPlan(index, val) {
      if (!this.isEditing) return;
      this.nutrition.dailyPlan.splice(index, 1, val);
    },
    updateMacro(ref, payload) {
      const { type, value } = payload;
      this.nutrition[ref][type].value = value;
    },
    updateMacroPercentage(ref, payload) {
      this.isPercentageValid[ref] = !payload;
    },
    hasData(macro) {
      const {
        c,
        p,
        f,
        total,
      } = macro;
      return !(c.value === 0 && p.value === 0 && f.value === 0 && total.value === 0);
    },
    cancelEditing() {
      this.nutrition = deepCopy(this.nutritionBackup);
      this.isEditing = false;
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
      isSaving: false,
      nutrition: null,
      nutritionBackup: null,
      isEditing: false,
      isPercentageValid: {
        maintenance: true,
        surplus: true,
        deficit: true,
      },
      usePercentInEditing: true,
      macros: [
        {
          name: 'Maintenance',
          slug: 'maintenance',
        },
        {
          name: 'Surplus',
          slug: 'surplus',
        },
        {
          name: 'Deficit',
          slug: 'deficit',
        },
      ],
    };
  },
  mounted() {
    this.fetchNutrition();
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
@import '@/scss/vars.scss';

form {
  &.is-editing {
    .macro.macro-surplus.active::before {
      background-color: transparent;
      border: 1px solid $primary;
    }

    .macro.macro-maintenance.active::before {
      background-color: transparent;
      border: 1px solid $label;
    }

    .macro.macro-deficit.active::before {
      background-color: transparent;
      border: 1px solid $danger;
    }
  }

  .macro {
    position: relative;
    height: 3rem;

    &::before {
      content: "";
      transition: all .2s ease-in-out;
      position: absolute;
      left: 2px;
      top: 4px;
      right: 2px;
      bottom: 4px;
      background-color: transparent;
      border-radius: $borderRadius;
    }

    &.active {
      &::before {
        background-color: rgba(0, 0, 0, .05);
      }

      .icon {
        opacity: 1;
      }
    }

    .icon {
      position: relative;
      z-index: 1;
      opacity: .25;
    }
  }
}

.weekplan {
  .weekday {
    flex: 0 1 14%;
  }

  .weekend {
    flex: 1 1 15%;
  }
}

.macro-card-container {
  margin: -.5rem;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
}

.macro-card {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: .5rem;

  @include media-breakpoint-down(md) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.macro-deficit, .macro-surplus {
  font-size: 1.5rem;
}

.donut-container {
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
}
</style>
